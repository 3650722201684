<template>
  <div class="lcf-notice">
    <NavBar title="公告详情" @click-left="onClickLeft">
      <template #left>
        <Icon name="cross" size="24" />
      </template>
    </NavBar>
    <div class="lcf-notice-detail">
      <p class="lcf-notice-title">{{ title }}</p>

      <div class="lcf-notice-content" v-html="content"></div>

      <p class="lcf-notice-time">{{ time }}</p>
    </div>
  </div>
</template>

<script>
import { NavBar, Icon, Notify } from 'vant'
import api from '@/api'

export default {
  props: ['id'],
  components: {
    NavBar,
    Icon,
  },
  data() {
    return {
      title: '',
      content: '',
      time: '',
    }
  },
  created() {
    this.requestNoticeDetail()
  },
  methods: {
    async requestNoticeDetail() {
      let notice = {}
      let error, response
      ;[error, response] = await to(api.general.noticeDetail(this.id))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (response?.data?.code === -1) {
        Object.assign(notice, response.data.data)
        this.content = notice.content
        this.title = notice.title
        this.time = notice.time
      }

      return notice
    },
    onClickLeft() {
      this.$router.replace({name: 'NoticeList'})
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-notice {
  font-size: 16px;
  .lcf-notice-detail {
    padding: 0 24px;
    .lcf-notice-title {
      color: $lcf-color-text-orange-light;
    }
    .lcf-notice-content,
    .lcf-notice-time {
      color: $lcf-color-text-gray;
    }
  }
}
</style>
